import React from "react";
import { Title } from "@yamaha-admin-sb/title";
import { Default } from "@/widgets/layouts";
import { colors } from "@/shared/helpers";
import styled from "styled-components";
import { ClientsTable } from "./ui/ClientsTable";

const StyledHead = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-bottom: 1px solid ${colors.gray200};

  @media (min-width: 768px) {
    padding: 20px 32px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 1024px) {
    padding: 16px 24px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const breads = [
  {
    title: "Клиенты",
    clickable: false,
  },
];

const Clients: React.FC = () => {
  return (
    <Default breads={breads}>
      <StyledHead>
        <StyledTitle>
          <Title level={2} color="gray-700">
            Клиенты
          </Title>
        </StyledTitle>
      </StyledHead>
      <ClientsTable />
    </Default>
  );
};

export default Clients;
