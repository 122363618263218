import type { FC } from "react";

export const InteriorIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 16.5L21 2C21 2 15.75 3 15.75 5C15.75 6.25 17.75 7 17 7.75C16.25 8.5 13.5 9.25 13.5 11.25C13.5 12.5 14.32 13.43 13.5 14.25C12.75 15 12 14.607 10.75 14.25C9.35 13.85 6.5 13.5 5.5 13.75C4.5 14 3.5 14.5 3.5 16C3.5 16.825 4 17.75 5.25 18C6.5 18.25 8 17 10 17C12 17 14.5 18.5 16 18.5C17.5 18.5 18 16.5 18 16.5Z"
        stroke="#7F8797"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M5.5 18L4 22H20.5L17.5 18" stroke="#7F8797" strokeWidth="2" strokeLinejoin="round" />
    </svg>
  );
};
