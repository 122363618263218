import React from "react";
import {
  ClientEntity,
  ContactEntity,
  ContactTypeEnum,
  useSetContactIsMainMutation,
} from "@/shared/lib/graphql";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import {
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
  StyledContactGroup,
  WrappedLeadTitle,
  WrappedBlock,
  MainContactIcon,
} from "../leads.styled";
import { Button } from "@yamaha-admin-sb/button";
import { Icon } from "@yamaha-admin-sb/icon";

interface IClientBlock {
  enableEdit: boolean;
  contact?: ContactEntity | null;
  client?: ClientEntity | null;
  onEditClick: () => void;
  isLinkReSending: boolean;
  reSendEmailLink: () => void;
  isShowEmailSendButton: boolean;
  setMainContact: () => void;
}

export const ClientBlock: React.FC<IClientBlock> = (props) => {
  const {
    enableEdit,
    contact,
    client,
    onEditClick,
    reSendEmailLink,
    setMainContact,
    isLinkReSending,
    isShowEmailSendButton,
  } = props;

  const { mutate: setIsMainContact } = useSetContactIsMainMutation();

  const phoneContacts = client?.contacts?.filter(
    (contact) => contact.type === ContactTypeEnum.Phone
  );
  const emailContacts = client?.contacts?.filter(
    (contact) => contact.type === ContactTypeEnum.Email
  );

  const handleSetIsMain = (id: number) => {
    setIsMainContact(
      {
        Id: id,
        input: {
          isMain: true,
        },
      },
      {
        onSuccess: () => {
          setMainContact();
        },
        onError: (error) => {
          console.log("error", error);
        },
      }
    );
  };

  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Клиент
        </Title>
        {enableEdit ? (
          <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
        ) : (
          <></>
        )}
      </WrappedLeadTitle>
      {client ? (
        <>
          <StyledNameItem>
            <Paragraph size={12} weight={500}>
              {client?.comment ?? ''}
            </Paragraph>
          </StyledNameItem>
          <StyledInfoItem>

            <StyledNameItem>
              <Paragraph size={12} color="gray-600" weight={500}>
                Имя
              </Paragraph>
            </StyledNameItem>
            <StyledValueItem>
              <a href={`/clients/${client?.id}`} target="_blank">
                <Paragraph size={12} color={"blue-200"} weight={400}>
                  {`${client?.name ? client?.name : client?.name ?? ""} ${client?.surname ? client?.surname : client?.surname ?? ""
                    }`}
                </Paragraph>
              </a>
            </StyledValueItem>
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledNameItem>
              <Paragraph size={12} color="gray-600" weight={500}>
                Телефон
              </Paragraph>
            </StyledNameItem>
            <StyledContactGroup>
              {phoneContacts?.map((contact) => {
                return (
                  <StyledValueItem key={contact.id}>
                    <a href={`tel:${contact?.value || ""}`}>
                      <Paragraph size={12} color={"blue-200"} weight={400}>
                        {contact?.value || "не указан"}
                      </Paragraph>
                    </a>
                    <MainContactIcon onClick={() => handleSetIsMain(contact.id)}>
                      <Icon
                        name="star"
                        color={`${contact.isMain ? "blue-200" : "neutral-white"}`}
                        size={16}
                      />
                    </MainContactIcon>
                  </StyledValueItem>
                );
              })}
            </StyledContactGroup>
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledNameItem>
              <Paragraph size={12} color="gray-600" weight={500}>
                E-mail
              </Paragraph>
            </StyledNameItem>
            <StyledContactGroup>
              {emailContacts?.map((contact) => {
                return (
                  <StyledValueItem>
                    <a href={`mailto:${contact?.value || ""}`}>
                      <Paragraph size={12} color={"gray-500"} weight={400}>
                        {contact?.value || "не указан"}
                      </Paragraph>
                    </a>
                    <MainContactIcon onClick={() => handleSetIsMain(contact.id)}>
                      <Icon
                        name="star"
                        color={`${contact.isMain ? "blue-200" : "neutral-white"}`}
                        size={16}
                      />
                    </MainContactIcon>
                  </StyledValueItem>
                );
              })}
            </StyledContactGroup>
          </StyledInfoItem>
          {/*  <StyledInfoItem>
            <StyledNameItem>
              <Paragraph size={12} color="gray-600" weight={500}>
                Телефон
              </Paragraph>
            </StyledNameItem>
            <StyledValueItem>
              <a href={`tel:${contact?.phone || ""}`}>
                <Paragraph
                  size={12}
                  color={contact?.phone ? "blue-200" : "gray-500"}
                  weight={400}
                >
                  {contact?.value || "не указан"}
                </Paragraph>
              </a>
            </StyledValueItem>
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledNameItem>
              <Paragraph size={12} color="gray-600" weight={500}>
                E-mail
              </Paragraph>
            </StyledNameItem>
            <StyledValueItem>
              {contact?.email ? (
                <a href={`mailto:${contact.email}`}>
                  <Paragraph size={12} color="gray-700" weight={400}>
                    {contact?.email || "Не указан"}
                  </Paragraph>
                </a>
              ) : (
                <Paragraph size={12} color="gray-500" weight={400}>
                  Не указан
                </Paragraph>
              )}
            </StyledValueItem>
          </StyledInfoItem> */}
          {isShowEmailSendButton && (
            <Button isDisabled={isLinkReSending} onClick={reSendEmailLink}>
              Отправить доступы
            </Button>
          )}
        </>
      ) : (
        <Title level={4} weight={400} color="gray-500">
          Информация не указана
        </Title>
      )}
    </WrappedBlock>
  );
};
