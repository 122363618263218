import type { FC } from "react";

export const DiagnosticIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 6H3C1.9 6 1 6.9 1 8V17H2C2 17.5304 2.21071 18.0391 2.58579 18.4142C2.96086 18.7893 3.46957 19 4 19C4.53043 19 5.03914 18.7893 5.41421 18.4142C5.78929 18.0391 6 17.5304 6 17H18C18 17.5304 18.2107 18.0391 18.5858 18.4142C18.9609 18.7893 19.4696 19 20 19C20.5304 19 21.0391 18.7893 21.4142 18.4142C21.7893 18.0391 22 17.5304 22 17H23V8C23 7.46957 22.7893 6.96086 22.4142 6.58579C22.0391 6.21071 21.5304 6 21 6ZM7.58 12.18C8.19 12.18 8.68 12.68 8.68 13.29C8.68 13.9 8.19 14.39 7.58 14.39C6.97 14.39 6.47 13.9 6.47 13.29C6.47 12.68 6.97 12.18 7.58 12.18ZM7.03 11.08L8.68 9.61H11.63L14.58 11.08H7.03ZM9.66 14.03H14.34C14.5 14.42 14.74 14.75 15.05 15H8.95C9.26 14.75 9.5 14.42 9.66 14.03ZM15.32 13.29C15.32 12.68 15.81 12.18 16.42 12.18C17.03 12.18 17.53 12.68 17.53 13.29C17.53 13.9 17.03 14.39 16.42 14.39C15.81 14.4 15.32 13.9 15.32 13.29ZM17.79 15C18.11 14.75 18.36 14.42 18.5 14.03H20.11V13.29C20.11 12.47 19.35 12.21 18.63 11.82L12 8.5H8.32L6.11 10.34H5.37C4.55 10.34 3.89 11 3.89 11.82V14.03H5.5C5.64 14.42 5.89 14.75 6.21 15H3V8H21V15H17.79Z"
        fill="#7F8797"
      />
    </svg>
  );
};
