import { colors, media, mediaRevers } from "@/shared/helpers";
import styled from "styled-components";

const WrappedLeadTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledNameItem = styled.span`
  min-width: 120px;
  width: 120px;
  margin-right: 8px;
`;
const StyledValueItem = styled.span`
  max-width: 187px;
  display: flex;
  gap: 12px;
`;

const MainContactIcon = styled.span`
  display: flex;
  cursor: pointer;
`;

const StyledInfoItem = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const WrappedBlock = styled.div`
  padding: 24px 40px 24px 0;
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 16px 20px;
`;

const StyledGridElement = styled.div<{ column: string }>`
  grid-column: ${({ column }) => column};
`;

const StyledFormItem = styled.div`
  padding: 16px 16px 24px;

  ${media.tablet} {
    padding: 16px 24px 24px;
  }
  ${media.laptop} {
    padding: 16px 24px 24px;
  }
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledLaptopReverseHidden = styled.div`
  ${mediaRevers.laptop} {
    display: none;
  }
`;
const StyledLaptopHidden = styled.div`
  ${media.laptop} {
    display: none;
  }
`;

const WrappedCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* ${media.desktop} {
  flex-direction: row;
  gap: 24px;
} */
`;

const StyledFlags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledContactGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export {
  StyledFlags,
  WrappedLeadTitle,
  StyledNameItem,
  StyledValueItem,
  StyledInfoItem,
  StyledContactGroup,
  MainContactIcon,
  WrappedBlock,
  StyledGridContainer,
  StyledFormItem,
  StyledGridElement,
  StyledLaptopReverseHidden,
  StyledLaptopHidden,
  WrappedCheckboxes,
};
