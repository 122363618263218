import React, { RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@yamaha-admin-sb/button";
import { Title } from "@yamaha-admin-sb/title";
import { StyledFormItem } from "@/entities/leads";
import {
  AttachmentCategoryEnum,
  LeadReportOutput,
  useDeleteAttachmentsMutation,
  useGetAttachmentsQuery,
  useGetReportQuery,
  useUpdateLeadReportMutation,
} from "@/shared/lib/graphql";
import styled from "@emotion/styled";
import { ModalContext, useAddNotification } from "@/shared/lib/react";
import { ReportPreview } from "./ReportPreview";
import { getDocument } from "../../model/getDocument";
import { downloadURL } from "@/shared/helpers";
import { ModalReportGallery } from "./ModalReportGallery";
import { ImageGallery } from "@/shared/ui";
import { useSendPhotos } from "../../model/useSendPhotos";

interface IReport {
  enableImageLoad: boolean;
  leadId: number;
  isDisabled: boolean;
  reports?: any[];
  isOffsiteDiagnostics: boolean;
  handleReportCreate: () => void;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  updateLead: () => void;
}

const StyledInnerBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled.div`
  margin-top: 20px;
`;

export const Report: React.FC<IReport> = (props) => {
  const {
    enableImageLoad,
    leadId,
    isDisabled,
    reports,
    isOffsiteDiagnostics,
    handleReportCreate,
    onDelete,
    onEdit,
    updateLead,
  } = props;
  const notify = useAddNotification();
  const { mutate: updateLeadReport, isLoading: isUpdationdReport } = useUpdateLeadReportMutation();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const [selectedReportId, setSelectedReportId] = useState(-1);
  const [selectedAttachmentsReportId, setSelectedAttachmentsReportId] = useState(-1);
  const [selectedReportCategory, setSelectedReportCategory] = useState<AttachmentCategoryEnum>(
    AttachmentCategoryEnum.NotSpecified
  );
  const [showPreview, setShowPreview] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const inputFile: RefObject<HTMLInputElement> = useRef(null);

  const [photoPublicLinks, setPhotoPublicLinks] = useState<{} | undefined>(undefined);

  const { mutate: deleteImage } = useDeleteAttachmentsMutation();

  const {
    data: images,
    refetch: updateImages,
    isLoading: isImagesLoading,
  } = useGetAttachmentsQuery({
    leadId,
  });

  const { uploadFiles, isImagesUploading, isLinkSending } = useSendPhotos({
    leadId,
  });

  const { data: reportData } = useGetReportQuery(
    {
      leadId: +leadId,
      input: {
        reportId: selectedReportId,
      },
    },
    {
      enabled: selectedReportId !== -1,
    }
  );

  useEffect(() => {
    const processImages = async () => {
      const getPublicLinks = await images?.getAttachments.reduce(async (accPromise, item) => {
        const acc = await accPromise;
        acc[item.id] = item.filePath ? await getDocument({ key: item.filePath }) : item.fileUrl;
        return acc;
      }, Promise.resolve({}));
      setPhotoPublicLinks(getPublicLinks);
    };

    processImages();
  }, [images]);

  const preparedImagesList = useMemo(
    () =>
      images?.getAttachments.reduce((acc, item) => {
        const itemCategory = item.reportCategory ?? AttachmentCategoryEnum.NotSpecified;
        const itemData = {
          ...item,
          fileUrl: item.fileUrl ? photoPublicLinks?.[item.id] : item.fileUrl,
          reportCategory: item.reportCategory ?? AttachmentCategoryEnum.NotSpecified,
        };
        return {
          ...acc,
          [item.reportId]: acc[item.reportId]
            ? {
                ...acc[item.reportId],
                [itemCategory]: acc[item.reportId][itemCategory]
                  ? [...acc[item.reportId][itemCategory], itemData]
                  : [itemData],
              }
            : {
                [itemCategory]: [itemData],
              },
        };
      }, {}),
    [photoPublicLinks]
  );

  useEffect(() => {
    if (reportData?.getReport) {
      updateLeadReport(
        {
          leadId,
          input: {
            id: selectedReportId,
            shouldGenerate: false,
            report: { ...reportData?.getReport, isPublic: true },
          },
        },
        {
          onSuccess: () => {
            setSelectedReportId(-1);
            updateLead();
            notify("Отчет отправлен клиенту", "success");
          },
          onError: (error) => {
            notify((error as ErrorEvent)?.message, "error");
          },
        }
      );
    }
  }, [reportData]);

  const handleSendReportToClient = (report: LeadReportOutput) => {
    setSelectedReportId(report.id);
  };

  const handleDownloadClick = async (id: number) => {
    const link = reports && reports.find((rep) => rep.id === id)?.filePath;

    if (!link) return;
    const fileUrl = await getDocument({ key: link });
    const filename = link.split("/").pop() as string;
    if (!fileUrl) return;
    await downloadURL(fileUrl, filename);
  };

  const handleViewClick = async (id: number) => {
    const link = reports?.find((rep) => rep.id === id)?.filePath;
    if (!link) return;

    window.open(await getDocument({ key: link }), "_blank");
  };

  const sortedReports = reports?.sort(function (a: LeadReportOutput, b: LeadReportOutput) {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
  });

  const handleImageDownload = (reportId: number, category: AttachmentCategoryEnum) => {
    setSelectedAttachmentsReportId(reportId);
    setSelectedReportCategory(category);
    inputFile.current?.click();
  };

  const handleDeleteAttahcment = (id: number) => {
    deleteImage(
      { leadId, input: { ids: [id] } },
      {
        onSuccess: () => updateImages(),
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  const handleViewGallery = (reportId: number, category: AttachmentCategoryEnum) => {
    setSelectedAttachmentsReportId(reportId);
    setSelectedReportCategory(category);
    setModalContext((prev) => ({ ...prev, modalReportGallery: true }));
  };

  return (
    <>
      {modalContext?.modalReportGallery &&
        selectedAttachmentsReportId &&
        preparedImagesList?.[selectedAttachmentsReportId]?.[selectedReportCategory]?.length > 0 && (
          <ModalReportGallery
            gallery={preparedImagesList?.[selectedAttachmentsReportId]?.[selectedReportCategory]}
            onClose={() => setModalContext((prev) => ({ ...prev, modalReportGallery: false }))}
            onItemClick={(index) => {
              setStartIndex(index);
              setShowPreview(true);
            }}
            onDownload={() =>
              handleImageDownload(selectedAttachmentsReportId, selectedReportCategory!)
            }
            onItemDelete={handleDeleteAttahcment}
            isLoading={isImagesUploading || isLinkSending}
            isDisabled={isDisabled || !enableImageLoad}
          />
        )}
      {showPreview &&
        selectedAttachmentsReportId &&
        selectedReportCategory &&
        preparedImagesList?.[selectedAttachmentsReportId]?.[selectedReportCategory]?.length > 0 && (
          <ImageGallery
            gallery={preparedImagesList?.[selectedAttachmentsReportId]?.[selectedReportCategory]}
            startIndex={startIndex}
            onClose={() => setShowPreview(false)}
          />
        )}
      <input
        type="file"
        accept="image/*"
        ref={inputFile}
        multiple={true}
        style={{ display: "none" }}
        onChange={(event) => {
          const eventCopy = { ...event };
          uploadFiles({
            reportId: selectedAttachmentsReportId,
            event: eventCopy,
            reportCategory: selectedReportCategory ?? undefined,
            onAllFilesUpload: () => {
              updateImages();
              notify("Все изображения загружены", "success");
            },
          });
        }}
      />
      <StyledFormItem>
        <div className="space-24">
          <Title level={4} weight={500} color="gray-700">
            Создание отчета
          </Title>
        </div>
        <StyledInnerBlock>
          <div className="m-w-100">
            {reports?.length ? (
              <>
                {sortedReports?.map((report) => (
                  <ReportPreview
                    isOffsiteDiagnostics={isOffsiteDiagnostics}
                    report={report}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onView={handleViewClick}
                    onDownload={handleDownloadClick}
                    handleSendReportToClient={handleSendReportToClient}
                    isUpdationdReport={isUpdationdReport}
                    isDisabled={
                      isUpdationdReport ||
                      (isOffsiteDiagnostics &&
                        preparedImagesList &&
                        !preparedImagesList[report?.id]?.length)
                    }
                    enableImageLoad={enableImageLoad}
                    onDeleteAttachment={handleDeleteAttahcment}
                    onDownloadAttachment={(category) => handleImageDownload(report.id, category)}
                    onViewAttachments={(category) => handleViewGallery(report.id, category)}
                    isLoading={isImagesLoading}
                    preparedImagesList={preparedImagesList}
                  />
                ))}
                {!isDisabled ? (
                  <StyledButton>
                    <Button variant="secondary" color="gray" onClick={handleReportCreate}>
                      Создать еще отчет
                    </Button>
                  </StyledButton>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Button isDisabled={isDisabled} onClick={handleReportCreate}>
                Создать отчет
              </Button>
            )}
          </div>
        </StyledInnerBlock>
      </StyledFormItem>
    </>
  );
};
