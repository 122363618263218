import React, { useEffect } from "react";
import styled from "styled-components";
import { Title } from "@yamaha-admin-sb/title";
import { ContactTypeEnum, GetClientQuery, useSetContactIsMainMutation, useUpdateClientMutation } from "@/shared/lib/graphql";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Icon } from "@yamaha-admin-sb/icon";
import { useNavigate } from "react-router-dom";
import { Controller, StyledTitle } from "@/shared/ui";
import { ClientLeads } from "./ClientLeads";
import { ClientCalls } from "./ClientCalls";
import { ITextarea, Textarea } from "@yamaha-admin-sb/textarea";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";

const StyledContent = styled.div`
  padding: 32px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
`;

const StyledAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--color-cian);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;


const StyledItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledItemRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr;
  gap: 24px;
`;

const StyledTitleBack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const StyledValueItem = styled.span`
  max-width: 187px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const MainContactIcon = styled.span`
  display: flex;
  cursor: pointer;
`;

const RowWithGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WrappedTextarea = styled.div`
  
`;

interface IClientLayout {
  user?: GetClientQuery;
  isSelf?: boolean;
  refetchClient: () => Promise<unknown>;
}

interface Contact {
  id: number;
  isMain?: boolean | null;
  name?: string | null;
  surname?: string | null;
  value?: string | null;
  type?: ContactTypeEnum | null;
}

interface GroupedContacts {
  phones: Contact[];
  emails: Contact[];
}

const ClientLayout: React.FC<IClientLayout> = (props) => {
  const { user, refetchClient } = props;
  const clientData = user?.getClient;
  const [comment, setComment] = React.useState(clientData?.comment);

  const navigate = useNavigate();

  const title = `${clientData?.name} ${clientData?.surname}`;
  const initials = user
    ? `${clientData?.name && clientData?.name[0]} ${clientData?.surname && clientData?.surname[0]}`
    : undefined;

  const { mutate: setIsMainContact } = useSetContactIsMainMutation();
  const { mutate: updateClient } = useUpdateClientMutation();

  const [debouncedCommentClient] = useDebounce(comment, 1000);

  const groupedContacts = (clientData?.contacts || []).reduce<GroupedContacts>(
    (acc, contact) => {
      if (contact.type === ContactTypeEnum.Phone) {
        acc.phones.push(contact);
      } else if (contact.type === ContactTypeEnum.Email) {
        acc.emails.push(contact);
      }
      return acc;
    },
    { phones: [], emails: [] }
  );

  const handleChange = () => {
    updateClient({
      id: user?.getClient?.id || 0,
      input: {
        comment: debouncedCommentClient,
      },
    })
  };

  useEffect(() => {
    handleChange();
  }, [debouncedCommentClient]);

  const {
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur" });

  const handleSetIsMain = (id: number) => {
    setIsMainContact(
      {
        Id: id,
        input: {
          isMain: true,
        },
      },
      {
        onSuccess: refetchClient,
        onError: (error) => {
          console.log("error", error);
        },
      }
    );
  };

  return (
    <>
      <StyledTitle className="">
        <StyledTitleBack onClick={() => navigate(-1)}>
          <Icon name="arrow-left" color="gray-500" size={24} />
          <Title level={2} weight={500} color="gray-700">
            Клиент {title}
          </Title>
        </StyledTitleBack>
      </StyledTitle>
      {user ? (
        <StyledItemList>
          {/*  <StyledContent> */}
          <StyledContent>
            {/*          <StyledAvatarWrapper>
              <StyledAvatar>
                <Title level={1} color="gray-100" weight={500}>
                  {initials || ""}
                </Title>
              </StyledAvatar>
              <Title level={2}>{`${clientData?.name} ${clientData?.surname}`}</Title>
            </StyledAvatarWrapper> */}
            <RowWithGap>
              <StyledItemList>
                {/*   <StyledItem>
                <Title level={3} weight={500} color="gray-600">
                  Контакты
                </Title>
              </StyledItem> */}

                <StyledItem>
                  <Paragraph size={14} weight={600} color="gray-600">
                    Телефоны
                  </Paragraph>
                  <StyledItemRows>
                    {groupedContacts.phones.map((item) => {
                      return (
                        <StyledValueItem key={item.id}>
                          <a href={`tel:${item?.value || ""}`}>
                            <Paragraph size={14} color="gray-700">
                              {item?.value || "не указан"}
                            </Paragraph>
                          </a>
                          <MainContactIcon onClick={() => handleSetIsMain(item.id)}>
                            <Icon
                              name="star"
                              color={`${item.isMain ? "blue-200" : "neutral-white"}`}
                              size={16}
                            />
                          </MainContactIcon>
                        </StyledValueItem>
                      );
                    })}
                  </StyledItemRows>
                </StyledItem>
                <StyledItem>
                  <Paragraph size={14} weight={600} color="gray-600">
                    Email
                  </Paragraph>
                  <StyledItemRows>
                    {groupedContacts.emails.map((item) => {
                      return (
                        <StyledValueItem>
                          <a href={`mailto:${item?.value || ""}`}>
                            <Paragraph size={12} color={"gray-500"} weight={400}>
                              {item?.value || "не указан"}
                            </Paragraph>
                          </a>
                          <MainContactIcon onClick={() => handleSetIsMain(item.id)}>
                            <Icon
                              name="star"
                              color={`${item.isMain ? "blue-200" : "neutral-white"}`}
                              size={16}
                            />
                          </MainContactIcon>
                        </StyledValueItem>
                      );
                    })}
                  </StyledItemRows>
                </StyledItem>
                {clientData?.city && (
                  <StyledItem>
                    <Paragraph size={14} weight={600} color="gray-600">
                      Город
                    </Paragraph>
                    <Paragraph size={14} color="gray-700">
                      {clientData?.city}
                    </Paragraph>
                  </StyledItem>
                )}

              </StyledItemList>
              <div>
                <Paragraph size={14} weight={600} color="gray-600">
                  Комментарий
                </Paragraph>
                <WrappedTextarea>
                  <Controller<ITextarea>
                    name="comment"
                    placeholder="Введите комментарий"
                    control={control}
                    errors={errors}
                    value={user.getClient.comment}
                    isBordered={true}
                    onChange={(value: string) => setComment(value)}
                    component={(inputProps: ITextarea) => <Textarea {...inputProps} />}
                  />
                </WrappedTextarea>
              </div>
            </RowWithGap>
          </StyledContent>

          {/*    <ClientDataTabs
                        selectedTab={currentTab}
                        leadsCount={clientData?.leads?.length ?? 0}
                        callsCount={clientData?.calls?.length ?? 0}
                        onTabClick={(val) => {
                            console.log("current", currentTab);

                            setCurrentTab(val)
                        }}
                    /> */}
          {/*  {currentTab === 'leads' && */}

          <ClientLeads isLoading={false} isPageLoaded={true} leads={clientData?.leads} />
          {/*  } */}
          {/* {currentTab === 'calls' && */}

          <ClientCalls clientId={user.getClient.id} />
          {/* } */}
          {/*   </StyledContent > */}
        </StyledItemList>
      ) : (
        <></>
      )}
    </>
  );
};

export default ClientLayout;
