import React from "react";
import { Breadcrumbs, IBreadcrumbsItem } from "@yamaha-admin-sb/breadcrumbs";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import Cookies from "js-cookie";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { colors, media } from "@/shared/helpers/index";
import { StyledLaptopHidden } from "@/entities/leads";

interface IHeader {
  breads: IBreadcrumbsItem[];
  onOpenClick: () => void;
}

const StyledLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${media.tablet} {
    gap: 32px;
  }
`;
const StyledTitle = styled.div`
  padding: 20px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet} {
    padding: 20px 32px;
  }
  ${media.laptop} {
    padding: 16px 24px;
  }
  border-bottom: 1px solid ${colors.gray200};
`;

const Header: React.FC<IHeader> = (props) => {
  const { breads, onOpenClick } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleLogout = () => {
    queryClient.removeQueries("getMe", {
      predicate: (query) => query.queryHash === `getMe-${Cookies.get("accessToken")}`,
    });
    Cookies.set("accessToken", "", { expires: -1 });
    Cookies.remove("accessToken");
    // navigate("/login");
    window.location.reload();
  };

  return (
    <StyledTitle>
      <StyledLeft>
        <StyledLaptopHidden>
          <IconButton
            onClick={onOpenClick}
            icon="burger"
            variant="tertiary"
            size="m"
            color="gray"
          />
        </StyledLaptopHidden>
        <Breadcrumbs breads={breads} onClick={(bread) => navigate(bread.path as string)} />
      </StyledLeft>
      <IconButton
        variant="secondary"
        color="blue"
        icon="logout"
        isSquared={true}
        onClick={handleLogout}
        size="s"
      />
    </StyledTitle>
  );
};

export { Header };
