import type { FC } from "react";

export const FolderIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_364_4)">
        <path
          d="M20.25 6.75001H12.3103L9.75 4.18969C9.61122 4.0498 9.44601 3.93889 9.26398 3.86341C9.08196 3.78792 8.88674 3.74938 8.68969 3.75001H3.75C3.35218 3.75001 2.97064 3.90804 2.68934 4.18935C2.40804 4.47065 2.25 4.85218 2.25 5.25001V18.8081C2.2505 19.1904 2.40257 19.5568 2.67286 19.8271C2.94316 20.0974 3.30962 20.2495 3.69188 20.25H20.3334C20.709 20.2495 21.069 20.1001 21.3346 19.8346C21.6001 19.569 21.7495 19.209 21.75 18.8334V8.25001C21.75 7.85218 21.592 7.47065 21.3107 7.18935C21.0294 6.90804 20.6478 6.75001 20.25 6.75001ZM3.75 5.25001H8.68969L10.1897 6.75001H3.75V5.25001ZM20.25 18.75H3.75V8.25001H20.25V18.75Z"
          fill="#7F8797"
          stroke="#7F8797"
        />
      </g>
      <defs>
        <clipPath id="clip0_364_4">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
