import { DocumentPreview } from "@/entities/document";
import { Loader } from "@/shared/ui";
import { AttachmentCategoryEnum, LeadReportOutput } from "@/shared/lib/graphql";
import { media, colors } from "@/shared/helpers";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { LeadGalleryCategory } from "@/entities/leads";
import { InteriorIcon } from "../../model/assets/InteriorIcon";
import { DiagnosticIcon } from "../../model/assets/DiagnosticIcon";
import { TechnicIcon } from "../../model/assets/TechnicIcon";
import { FolderIcon } from "../../model/assets/FolderIcon";
import { BodyIcon } from "../../model/assets/BodyIcon";

interface IReportPreview {
  preparedImagesList: {} | undefined;
  report: LeadReportOutput;
  isUpdationdReport: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  enableImageLoad: boolean;
  isOffsiteDiagnostics: boolean;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  onView?: (id: number) => void;
  onDownload?: (id: number) => void;
  handleSendReportToClient: (report: LeadReportOutput) => void;
  onDeleteAttachment: (id: number) => void;
  onDownloadAttachment: (category: AttachmentCategoryEnum) => void;
  onViewAttachments: (category: AttachmentCategoryEnum) => void;
}

const WrappedLoader = styled.div`
  margin: 8px 0;
`;

const WrappedButton = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;

  gap: 16px;

  ${media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledList = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  flex-direction: column;
  ${media.desktop} {
    gap: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const WrappedReportPreview = styled.div`
  border-bottom: 1px solid ${colors.gray200};
  &:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
`;

const WrappedInfo = styled.div`
  margin-top: 24px;
`;

const WrappedPhotoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
`;

const WrappedPhotoCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const ReportPreview: React.FC<IReportPreview> = (props) => {
  const {
    report,
    isUpdationdReport,
    isOffsiteDiagnostics,
    onDelete,
    onEdit,
    onView,
    onDownload,
    handleSendReportToClient,
    onDownloadAttachment,
    onViewAttachments,
    isDisabled,
    isLoading,
    preparedImagesList,
  } = props;

  const photoCategories = [
    {
      id: AttachmentCategoryEnum.Documents,
      name: "Документы",
      icon: "document",
      onDownload: () => onDownloadAttachment(AttachmentCategoryEnum.Documents),
      onView: () => onViewAttachments(AttachmentCategoryEnum.Documents),
    },
    {
      id: AttachmentCategoryEnum.Body,
      name: "Кузов",
      icon: <BodyIcon />,
      onDownload: () => onDownloadAttachment(AttachmentCategoryEnum.Body),
      onView: () => onViewAttachments(AttachmentCategoryEnum.Body),
    },
    {
      id: AttachmentCategoryEnum.Interior,
      name: "Салон",
      icon: <InteriorIcon />,
      onDownload: () => onDownloadAttachment(AttachmentCategoryEnum.Interior),
      onView: () => onViewAttachments(AttachmentCategoryEnum.Interior),
    },
    {
      id: AttachmentCategoryEnum.ComputeDiagnostics,
      name: "Диагностика",
      icon: <DiagnosticIcon />,
      onDownload: () => onDownloadAttachment(AttachmentCategoryEnum.ComputeDiagnostics),
      onView: () => onViewAttachments(AttachmentCategoryEnum.ComputeDiagnostics),
    },
    {
      id: AttachmentCategoryEnum.Technic,
      name: "Техника",
      icon: <TechnicIcon />,
      onDownload: () => onDownloadAttachment(AttachmentCategoryEnum.Technic),
      onView: () => onViewAttachments(AttachmentCategoryEnum.Technic),
    },
    {
      id: AttachmentCategoryEnum.NotSpecified,
      name: "Другие",
      icon: <FolderIcon />,
      onDownload: () => onDownloadAttachment(AttachmentCategoryEnum.NotSpecified),
      onView: () => onViewAttachments(AttachmentCategoryEnum.NotSpecified),
    },
  ];

  return (
    <>
      <WrappedReportPreview>
        <StyledList>
          <DocumentPreview
            key={report.id}
            name={report.fileName || "Отчет"}
            size={`${report.fileSize?.toString()} МБ` || ""}
            onDelete={() => onDelete && onDelete(report.id)}
            onEdit={() => onEdit && onEdit(report.id)}
            onView={() => onView && onView(report.id)}
            onDownload={() => onDownload && onDownload(report.id)}
          />
          <Paragraph size={12} color="gray-600">
            От {moment(report.createdAt).format("DD.MM.YYYY")}
          </Paragraph>
          <WrappedButton>
            {!report.isPublic ? (
              <Button
                isDisabled={isDisabled}
                isLoading={isUpdationdReport}
                color="gray"
                variant="secondary"
                onClick={() => handleSendReportToClient(report)}
              >
                Отправить клиенту
              </Button>
            ) : (
              <></>
            )}
          </WrappedButton>
        </StyledList>
        {isOffsiteDiagnostics && (
          <WrappedInfo>
            <Paragraph size={12} color="gray-600">
              Необходимо загрузить фото для отправки отчета клиенту
            </Paragraph>
          </WrappedInfo>
        )}
        {!isLoading ? (
          <WrappedPhotoBlock>
            Фото
            <WrappedPhotoCategory>
              {photoCategories.map((item) => (
                <LeadGalleryCategory
                  key={item.id}
                  {...item}
                  count={preparedImagesList?.[report.id]?.[item.id]?.length ?? 0}
                />
              ))}
            </WrappedPhotoCategory>
          </WrappedPhotoBlock>
        ) : (
          <WrappedLoader>
            <Loader size="m" />
          </WrappedLoader>
        )}
      </WrappedReportPreview>
    </>
  );
};
