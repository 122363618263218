import React from "react";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import { Icon } from "@yamaha-admin-sb/icon";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { DotedNavigation } from "@yamaha-admin-sb/doted-navigation";
import { IHiddenNavigationItem } from "@yamaha-admin-sb/hidden-navigation";

interface ILeadGalleryCategoryProps {
  name: string;
  icon: string | React.ReactNode;
  count: string;
  onView?: () => void;
  onDownload?: () => void;
}

const StyledFrame = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${colors.gray100};
  width: fit-content;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

type ActionType = "view" | "download";

const menu: IHiddenNavigationItem[] = [
  {
    title: "Просмотреть",
    icon: "view",
    value: "view",
    type: "black",
    isDisabled: false,
  },
  {
    title: "Загрузить фото",
    icon: "download",
    value: "download",
    type: "black",
    isDisabled: false,
  },
];

export const LeadGalleryCategory: React.FC<ILeadGalleryCategoryProps> = (props) => {
  const { name, count, icon, onView, onDownload } = props;

  const menuActions: Record<ActionType, (() => void) | undefined> = {
    view: onView,
    download: onDownload,
  };

  const handleDottedMenuClick = (item: IHiddenNavigationItem) => {
    const func = menuActions[item.value as ActionType];

    if (func) func();
  };

  return (
    <StyledFrame onClick={onView}>
      {typeof icon === "string" ? <Icon name={icon} color="gray-600" size={24} /> : icon}
      <div>
        <Paragraph size={12} color="black">
          {name}
        </Paragraph>
        <Paragraph size={10} color="gray-600">
          {count} фото
        </Paragraph>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DotedNavigation
          items={menu}
          onSelected={(item) => handleDottedMenuClick(item)}
          isDisabled={false}
        />
      </div>
    </StyledFrame>
  );
};
