import type { FC } from "react";

export const BodyIcon: FC = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.42 8.98074e-09C14.7447 -3.07559e-05 15.0645 0.0789812 15.3518 0.23021C15.6391 0.381439 15.8852 0.600339 16.069 0.868L16.156 1.008L19.736 7.273C19.8797 7.52456 19.9671 7.80435 19.992 8.093L20 8.266V12.764C20 13.012 19.9539 13.2579 19.864 13.489L19.789 13.659L18.671 15.894C18.5172 16.2019 18.2866 16.465 18.0014 16.6577C17.7162 16.8504 17.3861 16.9662 17.043 16.994L16.882 17H15C14.4954 17.0002 14.0094 16.8096 13.6395 16.4665C13.2695 16.1234 13.0428 15.6532 13.005 15.15L13 15H7C7.00016 15.5046 6.80959 15.9906 6.4665 16.3605C6.12341 16.7305 5.65316 16.9572 5.15 16.995L5 17H3.118C2.77414 16.9999 2.43613 16.9111 2.13659 16.7422C1.83706 16.5734 1.58612 16.3301 1.408 16.036L1.329 15.894L0.211 13.658C0.100315 13.4362 0.0318183 13.1958 0.00900006 12.949L8.9013e-10 12.764V8.266C-8.63144e-06 7.97665 0.062769 7.69073 0.184 7.428L0.264 7.273L3.844 1.008C4.00509 0.726076 4.23239 0.487611 4.50628 0.313209C4.78017 0.138806 5.0924 0.0337096 5.416 0.00699998L5.58 8.98074e-09H14.42ZM14.42 2H5.58L2 8.266V12.764L3.118 15H5V14.1C4.99986 13.8289 5.09981 13.5674 5.28067 13.3655C5.46152 13.1636 5.71056 13.0356 5.98 13.006L6.1 13H13.9C14.1711 12.9999 14.4326 13.0998 14.6345 13.2807C14.8364 13.4615 14.9644 13.7106 14.994 13.98L15 14.1V15H16.882L18 12.764V8.266L14.42 2ZM14.554 7.105C14.7911 6.98697 15.0654 6.96785 15.3167 7.05184C15.5679 7.13583 15.7755 7.31607 15.894 7.553C16.01 7.79079 16.028 8.0646 15.9442 8.31555C15.8605 8.5665 15.6816 8.77456 15.446 8.895C13.802 9.715 11.81 10 10 10C8.154 10 6.23 9.718 4.558 8.897C4.32252 8.77591 4.14355 8.56774 4.05915 8.31676C3.97474 8.06578 3.99155 7.79178 4.106 7.553C4.21807 7.33482 4.4057 7.16488 4.63388 7.0749C4.86205 6.98492 5.11518 6.98104 5.346 7.064L5.613 7.179L5.857 7.276L6.076 7.356L6.336 7.442L6.638 7.534L6.978 7.625L7.355 7.715L7.766 7.797C8.503 7.93202 9.25073 7.99997 10 8C11.516 8 13.168 7.768 14.554 7.105Z" fill="#7F8797" />
    </svg>

  );
};
